<script>
  export let logo,
    size = "h-10 w-10";
</script>

<svg viewBox="0 0 40 40" class={size}>
  {#if logo == "metamask"}
    <path
      d="M38 1.46 22.4 13.05l2.89-6.84Z"
      fill="#e2761b"
      stroke="#e2761b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m2 1.46 15.48 11.7-2.77-6.95Zm30.39 26.87-4.15 6.37 8.89 2.45 2.56-8.68Zm-32.06.14 2.54 8.68 8.89-2.45-4.15-6.37Z"
      fill="#e4761b"
      stroke="#e4761b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m11.26 17.57-2.48 3.75 8.84.39-.32-9.49Zm17.46 0-6.12-5.46-.2 9.6 8.82-.39Zm-17 17.13 5.31-2.59-4.58-3.58Zm11.16-2.59 5.32 2.59-.74-6.17Z"
      fill="#e4761b"
      stroke="#e4761b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m28.24 34.7-5.32-2.59.42 3.47-.05 1.42Zm-16.48 0 5 2.34v-1.46l.4-3.47Z"
      fill="#d7c1b3"
      stroke="#d7c1b3"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m16.78 26.24-4.42-1.3 3.12-1.43Zm6.42 0 1.3-2.73 3.14 1.43Z"
      fill="#233447"
      stroke="#233447"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m11.76 34.7.76-6.37-4.91.14Zm15.72-6.37.76 6.37 4.15-6.23Zm3.74-7-8.82.39.82 4.53 1.3-2.73 3.13 1.43Zm-18.86 3.61 3.14-1.43 1.28 2.73.84-4.53-8.84-.39Z"
      fill="#cd6116"
      stroke="#cd6116"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m8.78 21.32 3.71 7.21-.13-3.59Zm18.87 3.62-.15 3.59 3.72-7.21Zm-10-3.23-.84 4.53 1 5.35.23-7Zm4.78 0L22 24.53l.18 7.06 1.06-5.35Z"
      fill="#e4751f"
      stroke="#e4751f"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m23.22 26.24-1.06 5.35.76.52 4.58-3.58.15-3.59Zm-10.86-1.3.13 3.59 4.58 3.58.75-.52-1-5.35Z"
      fill="#f6851b"
      stroke="#f6851b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m23.29 37 .05-1.46-.34-.31h-6l-.37.35v1.46l-5-2.34 1.73 1.41L17 38.54h6l3.51-2.43 1.73-1.41Z"
      fill="#c0ad9e"
      stroke="#c0ad9e"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m22.92 32.11-.76-.52h-4.34l-.75.52-.4 3.47.37-.35H23l.39.35Z"
      fill="#161616"
      stroke="#161616"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="M38.67 13.8 40 7.4l-2-5.94-15.08 11.2 5.8 4.91L36.93 20l1.82-2.12-.75-.59 1.26-1.15-1-.75 1.26-1ZM0 7.4l1.33 6.4-.84.63 1.25 1-1 .75L2 17.29l-.79.56L3.06 20l8.2-2.4 5.81-4.91L2 1.46Z"
      fill="#763d16"
      stroke="#763d16"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    /><path
      d="m36.93 20-8.21-2.4 2.5 3.75-3.72 7.18 4.89-.06h7.3Zm-25.67-2.4L3.06 20 .33 28.47h7.28l4.88.06-3.71-7.21Zm11.14 4.11.52-9 2.38-6.5H14.71l2.36 6.45.55 9.05.18 2.85v7h4.34v-7Z"
      fill="#f6851b"
      stroke="#f6851b"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".16"
    />
  {:else if logo == "walletconnect"}
    <path
      d="M8.68 12.89a16.33 16.33 0 0 1 22.64 0l.75.73a.77.77 0 0 1 0 1.1l-2.57 2.51a.42.42 0 0 1-.57 0l-1-1a11.4 11.4 0 0 0-15.8 0L11 17.3a.4.4 0 0 1-.56 0l-2.58-2.5a.75.75 0 0 1 0-1.1Zm28 5.18 2.29 2.23a.77.77 0 0 1 0 1.1l-10.33 10a.82.82 0 0 1-1.13 0l-7.33-7.13a.19.19 0 0 0-.28 0l-7.33 7.13a.82.82 0 0 1-1.13 0l-10.37-10a.78.78 0 0 1 0-1.11l2.29-2.22a.8.8 0 0 1 1.13 0l7.33 7.13a.21.21 0 0 0 .28 0l7.33-7.13a.81.81 0 0 1 1.14 0l7.33 7.13a.21.21 0 0 0 .28 0l7.33-7.13a.8.8 0 0 1 1.13 0Z"
      fill="#3389fb"
    />
  {:else if logo == "bsc"}
    <path
      d="M13.36 8.39 9.49 6.14 20 0l10.54 6.14-3.87 2.25L20 4.52Zm19.93 3.86v4.52l3.86-2.24V10l-3.86-2.27L29.42 10ZM16.14 10 20 12.25 23.86 10 20 7.73Zm14.4 3.86-3.87-2.24L20 15.49l-6.64-3.86-3.87 2.24v4.52l6.65 3.87V30L20 32.24 23.86 30v-7.74l6.68-3.87Zm2.75 13.88-6.65 3.86v4.52L37.18 30V17.74L33.29 20Zm-6.65.65 3.87-2.24v-4.54l-3.87 2.24Zm-10.5 4.83v4.53L20 40l3.86-2.24v-4.53L20 35.48ZM2.82 14.53l3.86 2.24v-4.52L10.55 10 6.71 7.73 2.85 10v4.55ZM6.71 20l-3.86-2.23V30l10.54 6.15v-4.51l-6.68-3.89Zm6.65 3.86-3.87-2.22v4.52l3.87 2.24Z"
      fill="#f1ba0d"
    />
  {:else if logo == "clover"}
    <circle cx="10" cy="30" r="10" fill="#27a577" /><circle
      cx="10"
      cy="10"
      r="10"
      fill="#27a577"
    /><circle cx="30" cy="30" r="10" fill="#27a577" /><circle
      cx="30"
      cy="10"
      r="10"
      fill="#27a577"
    />
  {:else if logo == "trustwallet"}
    <path
      d="m20 4.31.78-1A1.28 1.28 0 0 0 20 3a1.31 1.31 0 0 0-.79.26Zm13.43 4h1.28a1.43 1.43 0 0 0-.09-.5 1.34 1.34 0 0 0-.28-.42 1.37 1.37 0 0 0-.42-.29 1.34 1.34 0 0 0-.49-.1ZM20 35.69l-.71 1.07A1.26 1.26 0 0 0 20 37a1.28 1.28 0 0 0 .71-.21ZM6.56 8.26V7a1.16 1.16 0 0 0-.49.1 1.34 1.34 0 0 0-.42.28 1.52 1.52 0 0 0-.28.43 1.37 1.37 0 0 0-.09.49ZM19.2 5.32c5.8 4.5 12.47 4.22 14.21 4.22V7c-1.8 0-7.6.23-12.63-3.68Zm12.93 2.91a101.33 101.33 0 0 1-.83 13.26 14.83 14.83 0 0 1-2 6.22 10.56 10.56 0 0 1-3.65 3.13c-1.65 1-3.76 2.07-6.35 3.79l1.45 2.13c2.45-1.64 4.5-2.69 6.24-3.72a13.8 13.8 0 0 0 4.46-3.9 18 18 0 0 0 2.44-7.26 105.68 105.68 0 0 0 .86-13.61Zm-11.41 26.4c-2.58-1.73-4.69-2.8-6.32-3.79a10.25 10.25 0 0 1-3.65-3.13 15.32 15.32 0 0 1-2.1-6.22 101.81 101.81 0 0 1-.81-13.26H5.28a105.55 105.55 0 0 0 .86 13.61 17.54 17.54 0 0 0 2.44 7.26A13.53 13.53 0 0 0 13 33c1.73 1 3.79 2.08 6.25 3.72ZM6.56 9.54c1.73 0 8.39.28 14.2-4.22l-1.57-2C14.15 7.2 8.35 7 6.56 7Z"
      fill="#3688eb"
      opacity=".9"
      style="isolation:isolate"
    />
  {:else if logo == "fortmatic"}
    <path
      d="M20 0h19.53v10H10.24v30H.47V0H20Zm9.76 30h-9.7V20h19.46v10.34a9.77 9.77 0 0 1-2.76 6.83A9.29 9.29 0 0 1 30.11 40h-.35Z"
      fill="#6851ff"
      fill-rule="evenodd"
    />
  {:else if logo == "coin98"}
    <path
      d="M3.06 26.62v.1a7.6 7.6 0 1 0 15.19 0v-.1h-3.59v.1a4 4 0 1 1-8 0v-.1Zm7.6-20.94a8.93 8.93 0 1 1-8.93 8.93 8.93 8.93 0 0 1 8.93-8.93Zm0 3.59a5.34 5.34 0 1 1-5.34 5.34 5.34 5.34 0 0 1 5.34-5.34Zm.61 3.6a1.85 1.85 0 1 1-1.23 0v-1.24h1.23Zm11.4 4.05a7.6 7.6 0 1 1 13.34 0 10.6 10.6 0 0 0-3.15-1.72 4 4 0 1 0-7 0 10.66 10.66 0 0 0-3.19 1.72Zm6.67 17.4a8.93 8.93 0 1 0-8.93-8.93 8.93 8.93 0 0 0 8.93 8.93Zm0-3.59A5.34 5.34 0 1 0 24 25.39a5.34 5.34 0 0 0 5.34 5.34Zm.62-3.6a1.86 1.86 0 1 0-1.23 0v1.24H30Z"
      fill="#deae30"
      fill-rule="evenodd"
    />
  {:else if logo == "coinbase"}
    <circle cx="20" cy="20" r="20" fill="#2058ea" /><circle
      cx="20"
      cy="20"
      r="11.8"
      fill="#fff"
    /><rect
      x="16.2"
      y="16.2"
      width="7.6"
      height="7.6"
      rx="1.11"
      fill="#1d55e7"
    />
  {:else if logo == "portis"}
    <path
      d="m7.27 23 1-.42L20 17.42 32.73 23l-4.79 10-11.69 1.62-8.78-9.57Z"
      fill="#133444"
    /><path
      d="M30.08 16.87a13 13 0 0 0-8.33-4.65h-.22a13.05 13.05 0 0 0-3.06 0h-.22a13 13 0 0 0-8.33 4.65l-.53.89-.88 1.48-.65 1.09a.1.1 0 0 1 0 .08l1.41.85 9 5.37 1.75 1v-12.8l-1.75.79V13.7l1.73-.79 1.75.79 8.86 4.06-.53-.89Z"
      fill="#c42370"
    /><path
      d="M33.12 26.2A14.13 14.13 0 0 1 28 37.05a13.41 13.41 0 0 1-4.14 2.32A12.14 12.14 0 0 1 20 40c-7.24 0-13.1-6.51-13.1-13.8a13.67 13.67 0 0 1 .39-3.2L20 30.59 32.75 23a14.81 14.81 0 0 1 .37 3.2Z"
      fill="#1c4d6b"
    /><path
      d="m18.47 12.18 1.53.71-1.75.79V12.1Zm-.22 3.44 1.75-.81v12.85l-1.75-1Z"
    /><path d="M20 14.81v12.85l12.2-7.25Z" fill="#1d4259" /><path
      d="M20 0v14.81l12.2 5.6Z"
      fill="#4b6b9a"
    /><path
      d="M20 14.81v12.85l-1.75-1-10.43-6.25a.1.1 0 0 1 0-.08l1.53-2.55 8.86-4.06v1.94Z"
      fill="#343535"
    /><path
      d="M20 14.81v12.85l-1.75-1-10.43-6.25 10.43-4.77Z"
      fill="#3e5578"
    /><path d="M20 0v14.81l-12.2 5.6Z" fill="#6db2d8" /><path
      d="m15.06 39 .55.24Zm.55.22a4.23 4.23 0 0 0 .57.2 5.49 5.49 0 0 1-.57-.25Zm1.06.36.43.11Z"
      fill="#335f8a"
    /><path
      d="M7.27 23 20 30.59a14.69 14.69 0 0 1-.92 3.41c-1 2.49-3.08 5.1-7.06 3.1a14.17 14.17 0 0 1-5.14-10.92A13.64 13.64 0 0 1 7.27 23Z"
      fill="#6db2d8"
    /><path
      d="M27.94 37.07c-.08.06 0 0-.25.22l-.12.08-.18.12-.21.16c.21-.16-.18.12-.26.18a.67.67 0 0 0-.21.13l-.14.1a.61.61 0 0 0-.14.1s-.06.06-.08.06-.09 0-.17.1a1.08 1.08 0 0 0-.26.14l-.27.14-.12.06c-.08 0 0 0-.12.05s-.17.08-.27.14a2.94 2.94 0 0 1-.34.16l-.17.08a4.84 4.84 0 0 0-.53.2l-.59.18-.25.08-.44.11h-.25l-.24.06h-.62a.15.15 0 0 0-.1 0h-.41L21 40h-2.23a.55.55 0 0 1-.18 0h-.33a.85.85 0 0 1-.22 0 .71.71 0 0 1-.2 0h-.21l-.24-.06h-.1a.37.37 0 0 1-.15 0l-.43-.11-.26-.08a.9.9 0 0 1-.23-.08c-.2-.06-.38-.14-.57-.2a4.56 4.56 0 0 1-.55-.24l-.28-.12a2.24 2.24 0 0 1-.27-.13 1.08 1.08 0 0 1-.26-.14l-.29-.42-.2-.12-.19-.12-.12-.08-.22-.1h-.07l-.2-.13-.24-.16-.25-.18-.22-.18-.07-.06a.78.78 0 0 1-.2-.16c4 2 6-.65 7-3.12a15.79 15.79 0 0 0 .98-3.44 14.74 14.74 0 0 0 .9 3.43c1.1 2.42 3.1 5.05 7.04 3.07Z"
      fill="#529bba"
    /><path
      d="m22.9 39.64.43-.11Zm.92-.27a5.49 5.49 0 0 0 .57-.2 5.49 5.49 0 0 1-.57.2Zm.53-.18c.18-.08.41-.16.59-.26-.19.07-.41.18-.59.26Z"
      fill="#335f8a"
    /><path
      d="M33.12 26.2A14.13 14.13 0 0 1 28 37.05c-4 2-6-.63-7.06-3.1a15.79 15.79 0 0 1-.94-3.36L32.73 23a13.67 13.67 0 0 1 .39 3.2Z"
      fill="#4b6b9a"
    />
  {:else if logo == "tokenpocket"}
    <path
      d="M16.11 12.21V3.79H.53a.5.5 0 0 0-.53.53v11.89a.5.5 0 0 0 .53.53h6v21.68a.5.5 0 0 0 .52.53h9.79a.5.5 0 0 0 .53-.53V12.21Z"
      fill="#29aeff"
    /><path
      d="M27 1.05H13a.5.5 0 0 0-.53.53v34.1a.5.5 0 0 0 .53.53h9.79a.5.5 0 0 0 .52-.53v-8.63h3.79a13 13 0 1 0-.1-26Z"
      fill="#2761e7"
    />
  {:else if logo == "torus"}
    <path
      d="M5.17 0a3.75 3.75 0 0 0-3.76 3.74v4.37a3.75 3.75 0 0 0 3.76 3.74h6.65v24.41A3.75 3.75 0 0 0 15.58 40H20a3.76 3.76 0 0 0 3.76-3.74V3.74A3.76 3.76 0 0 0 20 0H5.17Z"
      fill="#0364ff"
      fill-rule="evenodd"
    /><path
      d="M32.64 11.85a5.93 5.93 0 1 0-5.95-5.92 5.93 5.93 0 0 0 5.95 5.92Z"
      fill="#0016de"
    />
  {:else if logo == "keystone"}
    <path
      d="M23.11 5a3.34 3.34 0 0 1-.51 1.86l-14.4 25c-.57 1-1.5 1-2 0a11.93 11.93 0 0 1-1.66-6.33 6.14 6.14 0 0 1 .83-2.66q4.78-8.38 9.59-16.76 1.35-2.35 2.69-4.72a2.12 2.12 0 0 1 3.93 0c.32.6.68 1.19 1 1.78A3.29 3.29 0 0 1 23.11 5ZM26 35.42a3.68 3.68 0 0 1-.86 2.35L25 38c-1.7 1.87-3 2.83-6 1.22-1.94-1-4-1.9-6-2.83a3.47 3.47 0 0 1-1.64-5.24c.84-1.47 1.67-2.94 2.53-4.38a2.72 2.72 0 0 1 4.53-.53c2.1 2.09 4.19 4.2 6.29 6.28a4 4 0 0 1 1.29 2.9Z"
      fill="#fff"
    /><path
      d="M27.35 23h4.21a3.85 3.85 0 0 1 3.6 2 2.76 2.76 0 0 1-.39 3.2c-1.11 1.16-2.25 2.31-3.43 3.4a3 3 0 0 1-4.19-.09c-1.84-1.82-3.67-3.67-5.5-5.5a1.72 1.72 0 0 1-.5-2A1.74 1.74 0 0 1 22.9 23h4.45Z"
      fill="#2362fe"
    /><path
      d="M24.77 20.58h-3a2.38 2.38 0 0 1-2.16-3.74c1-1.72 2-3.44 3-5.15a2.74 2.74 0 0 1 4.94 0q1.35 2.3 2.65 4.61a2.73 2.73 0 0 1-2.5 4.29c-1.01 0-1.97-.01-2.93-.01Z"
      fill="#fff"
    />
  {/if}
</svg>
