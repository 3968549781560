<script>
  export let title,
    btn,
    w = "w-full sm:max-w-sm max-w-full",
    subtitle;
  import { fly, fade } from "svelte/transition";
  import { backOut } from "svelte/easing";
  let show;
  import { onMount, createEventDispatcher } from "svelte";
  import classNames from "../helpers/classMerger";

  const dispatch = createEventDispatcher();

  onMount(() => {
    show = true;
  });
</script>

{#if show}
  <div
    class="fixed z-20 inset-0 overflow-y-auto backdrop-blur"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        on:click
        transition:fade|local
        class="fixed backdrop-blur z-10 inset-0 bg-dark bg-opacity-50 w-full"
        aria-hidden="true"
      />

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true">&#8203;</span
      >

      <div
        in:fly={{ y: 20, easing: backOut }}
        out:fly|local={{ y: 20 }}
        class="inline-block align-bottom bg-dark border border-light rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl relative z-20 transform transition-all sm:my-8 sm:align-middle {w} sm:w-full sm:p-6 mb-64 sm:mb-40 bg-zinc-800"
      >
        <div>
          <div
            class={classNames(
              "sm:mt-0",
              subtitle ? "text-left" : "text-center"
            )}
          >
            {#if title}
              <h3
                class="text-lg leading-6 font-medium text-white mb-3"
                id="modal-title"
              >
                {title}
              </h3>

              {#if !subtitle}
                <div class="relative">
                  <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div class="w-full border-t border-light" />
                  </div>
                </div>
              {/if}
            {/if}

            {#if subtitle}
              <h4
                class="text-lg leading-6 font-medium text-white mb-3"
                id="modal-subtitle"
              >
                {subtitle}
              </h4>
            {/if}

            <div class="mt-6">
              <slot />
            </div>
          </div>
        </div>
        {#if btn}
          <div class="mt-5 sm:mt-6">
            <button
              on:click={() => dispatch("action")}
              class="flex mx-auto justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              {btn}
            </button>
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}
