<script>
  import ConnectBtn from "./ConnectBtn.svelte";
</script>

<div
  class="w-full flex flex-col lg:flex-row items-center justify-between py-2 pt-4 px-8 space-y-4 lg:space-y-0"
>
  <span>
    <img class="max-h-24" src="/images/high-monks-logo.png" alt="logo" />
  </span>
  <div
    class="w-full flex items-center justify-end text-white text-base text-right"
  >
    <span class="hidden lg:flex space-x-8 mr-16"
      ><a
        class="force-white"
        href="https://thehighmonks.com/roadmap-2/"
        target="_blank">Roadmap</a
      >
      <a
        class="force-white"
        href="https://discord.com/invite/7J2NwPBYsY"
        target="_blank">Community</a
      ></span
    >
    <ConnectBtn />
  </div>
</div>
