<script>
  import Button from "./Button.svelte";
  import { showClaimModal, showConnectModal } from "./../stores/walletStore.js";
  import Modal from "./Modal.svelte";
  import ConnectOptions from "./ConnectOptions.svelte";
</script>

{#if $showConnectModal}
  <Modal
    on:click={() => ($showConnectModal = false)}
    w="w-full sm:max-w-xl max-w-full"
    title="Connect Wallet"
    on:action={() => ($showConnectModal = !$showConnectModal)}
  >
    <ConnectOptions />
  </Modal>
{/if}

{#if $showClaimModal}
  <Modal
    on:click={() => ($showClaimModal = false)}
    w="w-full sm:max-w-xl max-w-full"
    title="Claim"
    subtitle="Enter an address to check if you can mint the NFT"
    on:action={() => ($showClaimModal = !$showClaimModal)}
  >
    <div class="mt-1">
      <input
        type="text"
        name="nftAddress"
        id="nftAddress"
        class="no-blue bg-zinc-700 shadow-sm focus:bg-zinc-600 focus:border-zinc-600 focus:ring-zinc-600 block w-full sm:text-sm border-gray-300 rounded-md py-2 text-white transition-all duration-200"
        placeholder="0x...EE"
      />
    </div>
    <Button
      classes="justify-center mt-4 font-bold tracking-wide text-base"
      label="Check"
      stretch
    />
  </Modal>
{/if}
