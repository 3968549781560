<script>
  import { loading, numberAvailableToMint } from "./../stores/niftyStore.js";
  import { toasts } from "svelte-toasts";
  import Button from "./Button.svelte";
  export let image;
  import { contracts } from "svelte-ethers-store";

  const mintWhiteListed = async () => {
    try {
      $loading = true;
      if ($numberAvailableToMint > 0) {
        toasts.info("Please Wait");
        await $contracts.highMonksAirdrop.mintWhitelisted(1);
        $numberAvailableToMint -= 1;
      } else {
        toasts.error("No more tokens to mint");
      }
    } catch (error) {
      toasts.error(error.message);
    } finally {
      $loading = false;
    }
  };
</script>

<div>
  <img class="rounded-sm" src={`/images/${image}`} alt="nft-images" />
  <div class="flex w-full items-center justify-center gap-2 my-8">
    <!-- <div class="grid grid-cols-2 gap-2 my-8"> -->
    <Button
      classes="font-bold tracking-wide text-base px-16"
      centerText
      label={$loading
        ? "Loading"
        : $numberAvailableToMint
        ? "Claim"
        : "Unavailable"}
      on:click={mintWhiteListed}
      disabled={$loading || !$numberAvailableToMint}
    />
    <!-- <Button
      label="Claim"
      classes="font-bold tracking-wide text-base px-16"
      centerText
      on:click={mintWhiteListed}
    /> -->
    <!-- <Button
      label="Transfer"
      classes="font-bold tracking-wide text-base"
      centerText
      disabled
    /> -->
  </div>
</div>
