<script>
  import NftCard from "./NftCard.svelte";

  const images = ["ETHER 1.jpg"];
</script>

<div class="w-full flex items-center justify-center px-4 lg:px-0 mx-auto">
  <div class="grid lg:grid-cols-3 gap-5 gap-x-16 max-w-6xl">
    {#each images as image}
      <NftCard {image} />
    {/each}
  </div>
</div>

<div class="w-full py-6 pb-14 mx-auto flex justify-center">
  <p class="text-white text-center max-w-5xl">
    Guardians are either Sativa/indica dominant androids created by the Elders
    of The High Monks to guard and guide the realms. Claim your guardians for
    free! just pay gas fees.
  </p>
</div>
