import { readable, writable } from "svelte/store";

// We need this to pull the actual number that has been minted
export const contractAddress = readable(
  "0xF983115c5bf1336FdF1b831700DC3f83e28023CE"
);

export const minted = writable(0);

export const ownedNFTs = writable([]);

export const numberAvailableToMint = writable(0);

export const loading = writable(false);
