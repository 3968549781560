<script>
  import { loading } from "./../stores/niftyStore.js";
  import accountShortner from "./../helpers/accountShortner.js";
  import {
    connectedWalletAddress,
    walletProvider,
  } from "./../stores/walletStore.js";
  import { connected, showConnectModal } from "../stores/walletStore.js";
  import Button from "./Button.svelte";
  import { onMount } from "svelte";
  import detectEthereumProvider from "@metamask/detect-provider";
  import isWalletConnected from "../helpers/isWalletConnected.js";
  import {
    defaultEvmStores,
    signerAddress,
    signer,
    contracts,
  } from "svelte-ethers-store";
  import walletProviders from "../constants/walletProviders.js";
  import {
    contractAddress,
    numberAvailableToMint,
  } from "../stores/niftyStore.js";
  import highMonksAirdrop from "../contracts/highMonksAirdrop.json";

  onMount(async () => {
    try {
      if (await detectEthereumProvider()) {
        if (window.ethereum.isMetaMask) {
          if (await isWalletConnected()) {
            await defaultEvmStores.setProvider();
            defaultEvmStores.attachContract(
              "highMonksAirdrop",
              $contractAddress,
              highMonksAirdrop,
              $signer
            );
            // initUtils();
            $connectedWalletAddress = $signerAddress;
            $walletProvider = walletProviders.Metamask;
            $connected = true;
            await checkAvailability();
          }
        }
      }
    } catch (error) {
      // toasts.error(error.message);
    }
  });

  const checkAvailability = async () => {
    $loading = true;
    try {
      $numberAvailableToMint =
        await $contracts.highMonksAirdrop.numberAvailableToMint(
          $connectedWalletAddress
        );
    } catch (error) {
      $numberAvailableToMint = 0;
    } finally {
      $loading = false;
    }
  };

  const checkConnect = () => {
    if ($connected) {
      // requestedDisconnect = true;
      resetConnection();
    } else {
      $showConnectModal = true;
    }
  };

  const resetConnection = () => {
    $connected = false;
    $connectedWalletAddress = "";
    $walletProvider = "";
  };
</script>

<div
  class="space-x-2 w-full lg:w-auto flex lg:block items-center justify-center"
>
  <Button
    on:click={checkConnect}
    label={!$connected ? "Connect" : "Disconnect"}
    classes="px-8"
  />

  {#if $connectedWalletAddress}
    <span class="p-[0.35rem] border-2 rounded-md text-white text-base"
      >{accountShortner($connectedWalletAddress)}</span
    >
  {/if}
</div>
