import { writable, readable } from "svelte/store";

export let pricePer = readable(0.44);

export let amountToMint = writable(1);
export let connected = writable(false);
export let showConnectModal = writable(false);
export let showClaimModal = writable(false);
export let connectedWalletAddress = writable("");
export let walletProvider = writable("");

// User Wallet Balance
export let walletBalance = writable(null);
