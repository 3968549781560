<script>
  import classNames from "../helpers/classMerger";

  export let label,
    type = "button",
    stretch = false,
    classes = "",
    centerText,
    disabled = false;
</script>

<button
  {type}
  on:click
  class={classNames(
    "btn",
    stretch ? "w-full" : "",
    classes,
    centerText ? "justify-center" : ""
  )}
  {disabled}
>
  {label}
</button>
