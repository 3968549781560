<script>
  import { contractAddress } from "./../stores/niftyStore.js";
  import {
    defaultEvmStores,
    signerAddress,
    contracts,
    signer,
  } from "svelte-ethers-store";
  import { loading, numberAvailableToMint } from "../stores/niftyStore";
  import {
    connectedWalletAddress,
    connected,
    showConnectModal,
    walletProvider,
  } from "../stores/walletStore";
  import highMonksAirdrop from "../contracts/highMonksAirdrop.json";
  import OptionLogo from "./OptionLogo.svelte";

  let options = [
    {
      name: "Metamask",
      logo: "metamask",
    },
    // {
    //   name: "WalletConnect",
    //   logo: "walletconnect",
    // },
    // {
    //   name: "Trust Wallet",
    //   logo: "trustwallet",
    // },
    // {
    //   name: "Binance",
    //   logo: "bsc",
    // },
    // {
    //   name: "Clover",
    //   logo: "clover",
    // },
    // {
    //   name: "Coinbase",
    //   logo: "coinbase",
    // },
    // {
    //   name: "Fortmatic",
    //   logo: "fortmatic",
    // },
    // {
    //   name: "Keystone",
    //   logo: "keystone",
    // },
    // {
    //   name: "Portis",
    //   logo: "portis",
    // },
    // {
    //   name: "Coin98",
    //   logo: "coin98",
    // },
    // {
    //   name: "TokenPocket",
    //   logo: "tokenpocket",
    // },
    // {
    //   name: "Torus",
    //   logo: "torus",
    // },
  ];

  const setContract = () => {
    defaultEvmStores.attachContract(
      "highMonksAirdrop",
      $contractAddress,
      highMonksAirdrop,
      $signer
    );
  };

  const checkAvailability = async () => {
    $loading = true;
    try {
      $numberAvailableToMint =
        await $contracts.highMonksAirdrop.numberAvailableToMint(
          $connectedWalletAddress
        );
    } catch (error) {
      $numberAvailableToMint = 0;
    } finally {
      $loading = false;
    }
  };

  function setState(address, provider) {
    $connected = true;
    $connectedWalletAddress = address;
    $walletProvider = provider;
    $showConnectModal = false;
    setContract();
    checkAvailability();
  }

  async function connectWallet(provider) {
    try {
      // let walletAddress = "0x9550E4dB2890c45D0f741D10c16B1feD39F5e9E1";
      // walletAddress = `${walletAddress.substring(
      //   0,
      //   4
      // )}...${walletAddress.substring(walletAddress.length - 2)}`;

      await defaultEvmStores.setProvider();

      // This should come from the connect above
      const walletAddress = $signerAddress;

      // Set Contract when Connected
      // defaultEvmStores.attachContract(
      //   "pixelPupsContract",
      //   $contractAddress,
      //   pixelPupscontract,
      //   $signer
      // );

      setState(walletAddress, provider);
    } catch (error) {}
  }
</script>

<div class="grid grid-cols-2 sm:grid-cols-3 gap-2">
  {#each options as { name, logo }}
    <button
      on:click={() => connectWallet(name)}
      class="rounded-xl flex flex-col items-center justify-center py-4 w-full bg-mid hover:bg-zinc-900 text-white"
    >
      <OptionLogo {logo} />
      {name}
    </button>
  {/each}
</div>
